export const environment = {
  production: true,
  oidcAuthority: 'https://stage.id.indigo.si',
  oidcClientId: '3f76873a-c917-42ad-ad9f-960039f512ef',
  oidcScope: 'openid profile offline_access',
  oidcLogLevel: 0, // None = 0, Debug = 1, Warn = 2, Error = 3
  oidcResponseType: 'code',
  defaultLanguage: 'en',
  apiUrl: 'https://stage.api.folks.indigo.si'
};
